'use client';
import { captureException } from '@sentry/nextjs';
import { SimpleImage } from 'components/simple-image';
import { Button, Center, Spacer, Typo } from 'components/ui-kit';
import { JSX, useState } from 'react';

type TProps = {
  error: Error & { digest?: string };
  reset: VoidFunction;
};

export default function GlobalError({ error, reset }: TProps): JSX.Element {
  const [errorCode] = useState<string>(() => captureException(error));

  return (
    <html>
      <body>
        <script
          dangerouslySetInnerHTML={{
            __html: `!function(t){function e(){var e=this||self;e.globalThis=e,delete t.prototype._T_}"object"!=typeof globalThis&&(this?e():(t.defineProperty(t.prototype,"_T_",{configurable:!0,get:e}),_T_))}(Object);`,
          }}
        />
        <div style={{ height: '100vh', overflow: 'hidden' }}>
          <Spacer size={32} />
          <Center>
            <SimpleImage
              layout="fixed"
              src="/images/error-logo.svg"
              alt="Универмаг"
              width={267}
              height={67}
            />
          </Center>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <Center>
              <div style={{ maxWidth: 620 }}>
                <Typo variant="4xl" align="center">
                  С&nbsp;этой страницей что-то не&nbsp;так. Попробуйте вернуться
                  на&nbsp;главную.
                </Typo>
                <Spacer size={12} />
                <Typo variant="xl" align="center">
                  Код ошибки: {errorCode}
                </Typo>
                <Spacer size={44} />
                <Center>
                  <Button size="xl" variant="secondary" onClick={reset}>
                    На&nbsp;главную
                  </Button>
                </Center>
              </div>
            </Center>
          </div>
        </div>
      </body>
    </html>
  );
}
